import React, { lazy, Suspense } from 'react'

const AccessLevelPage = lazy(() => import('./AccessLevelPage'))

const AccessLevelPageLazy = () => {
  return (
    <Suspense fallback={null}>
      <AccessLevelPage />
    </Suspense>
  )
}

export { AccessLevelPageLazy }
